/* eslint-disable no-undef */
/* eslint-disable no-unreachable */
import { buildEECProduct } from '@/utils/tracking';
import { localStorageCheck } from '@/utils/localStorage';
import { getCookie } from '@/utils/cookie';
import { debounce } from 'lodash';

export const state = () => ({
  isOpen: false,
  cart: {},
  checkout: {},
  payload: {},
  isAsyncSubmitting: 0,
  isSubmitting: 0,
  freeGiftThreshold: 0,
});

const debouncedUpdateLineItems = debounce(async function ({ state, commit, dispatch, getters }) {
  commit('SET_SUBMITTING', true);

  // await dispatch('initCart');

  const payload = Object.entries(state.payload).map(([id, quantity]) => {
    return {
      id,
      quantity,
    };
  });

  commit('RESET_PAYLOAD');

  const removeBundles = [];

  // Here we check if we need to remove any bundles.
  // If the quantity is 0, we search & remove all related bundles.
  // Bundle relationships are stored in the line items custom attribute (bundledBy)
  payload.forEach((item) => {
    if (item.quantity <= 0) {
      const currentItem = getters.lineItems.find((li) => li.id === item.id);
      getters.lineItems
        .filter((li) => {
          return (
            li.attributes.findIndex((attr) => {
              return attr.key === '_bundledBy' && attr.value === currentItem?.variant?.product?.handle;
            }) !== -1
          );
        })
        .forEach((bundledItem) => {
          const bundledByProductHandle = bundledItem.attributes.find((attr) => attr.key === '_bundledBy').value;

          const countParentProducts = getters.lineItems.filter(
            (item) => item.variant.product.handle === bundledByProductHandle
          ).length;

          // we remove only if there is only one parent product left
          if (countParentProducts <= 1) {
            removeBundles.push({
              id: bundledItem.id,
              quantity: 0,
            });
          }
        });
    }
  });

  const finalPayload = payload.concat(removeBundles);

  dispatch('trackLineItemChanges', finalPayload);

  const cart = await this.$shopifyClient.updateLines(state.cart.id, finalPayload);

  commit('SET_CART', cart);
  await dispatch('pickystoryRefreshCart', cart);

  commit('SET_SUBMITTING', false);
}, 450);

/**
 * build EEC products from variants and quantity , fetch shopify-product to enrich missing data like product.title and product.productType
 * @param {Array} variantsDeltas - array of objects with keys variant and delta
 * @param {Object} client - shopifyBuyCLient provides product fetching
 * @returns {Array} array of EEC products
 */
export const gtmEECProducts = async (variantsDeltas, client) => {
  return await Promise.all(
    variantsDeltas.map(async ({ variant, delta }) => {
      const shopifyProduct = await client.product.fetch(variant.product.id);
      return buildEECProduct(variant, shopifyProduct, delta);
    })
  );
};

export const actions = {
  async open({ commit, dispatch, state }) {
    await dispatch('initCart');

    if (!state.isOpen) {
      commit('OPEN');
      this.$_gtm.push({
        event: 'virtPageView',
        page_type: 'cartFlyout',
        page_path: '/cart/flyout',
        page_title: 'Cart Flyout',
        content_category: 'cart',
        collection: null,
        search_results: null,
        product_category: null,
        product_subcategory: null,
        content_subcategory: null,
      });
    }
  },

  close({ commit }) {
    commit('CLOSE');
  },

  /**
   * initialize cart from locally stored cartId
   * or register new cart
   */
  async initCart({ state, commit, dispatch }) {
    if (state.cart && state.cart.id) return;

    if (!localStorageCheck()) {
      console.warn('no localstorage available, cannot init cart');
    }

    const cartId = localStorage.getItem(`shopify_cart_${this.$i18n.locale}_id`);

    let cart = null;

    // if no cartId is stored, create new cart
    if (!cartId) {
      // create new cart
      cart = await this.$shopifyClient.createCart(this.$shopifyClient.resolveLocale());

      commit('SET_CART', cart);
      dispatch('persistCart', cart.id);
    } else {
      // try to get the cart from shopify
      cart = await this.$shopifyClient.getCart(cartId);

      if (cart && cart.id) {
        commit('SET_CART', cart);
      } else {
        // if the cart id does not exist, remove it from storage and reset cart
        localStorage.removeItem(`shopify_cart_${this.$i18n.locale}_id`);

        dispatch('resetCart');
      }
    }

    // finally check for voucher and giftcard in url
    const $route = this.app.router.currentRoute;

    if ($route.query.voucher) {
      dispatch('applyDiscount', $route.query.voucher);
    }
  },

  async refreshCheckoutAndAttributes({ state, commit, dispatch, getters }) {
    commit('SET_SUBMITTING', true);

    const payload = getters.lineItems
      .filter((lineItem) => {
        return lineItem.attributes.findIndex((attr) => attr.key === '_offer') !== -1;
      })
      .map((lineItem) => {
        return {
          id: lineItem.id,
          quantity: lineItem.quantity,
          attributes: [],
        };
      });

    if (payload) {
      const checkout = await this.$shopifyClient.updateLines(state.cart.id, payload);
      commit('SET_CART', checkout);

      await dispatch('pickystoryRefreshCart', checkout);
    }

    commit('SET_SUBMITTING', false);

    return Promise.resolve();
  },

  async refreshCheckout({ state, commit }) {
    if (!state.cart?.id) {
      return;
    }

    commit('SET_SUBMITTING', true);
    let cart = null;

    try {
      cart = await this.$shopifyClient.getCart(state.cart.id);
      commit('SET_CART', cart);
    } catch (e) {
      this.$sentry.captureException(e);
    } finally {
      commit('SET_SUBMITTING', false);
    }
  },

  async applyDiscount({ commit, state }, discountCode) {
    try {
      const cart = await this.$shopifyClient.addDiscount(state.cart.id, [discountCode]);

      commit('SET_CART', cart);
      commit(
        'toast/add',
        {
          message: this.$i18n.t('cart.discountApplied'),
          reason: 'success',
          type: 'success',
        },
        { root: true }
      );
    } catch (error) {
      console.log('error', error);

      commit(
        'toast/add',
        {
          message: this.$i18n.t('cart.discountInvalid'),
          reason: error.message,
          type: 'error',
        },
        { root: true }
      );
    }
  },

  /**
   * adds lineItems to to cart of format [{ variantId, quantity}]
   */
  async addLineItems({ state, commit, dispatch }, lineItems) {
    await dispatch('initCart');

    try {
      const cart = await this.$shopifyClient.addLines(state.cart.id, lineItems);
      await dispatch('pickystoryRefreshCart', cart);
      commit('SET_CART', cart);
    } catch (e) {
      console.error(e);
    }
  },

  async removeLineItems({ state, commit, dispatch }, variantIds) {
    state.cart.lineItems
      .filter((lineItem) => {
        return variantIds.some((variantId) => {
          return lineItem.variant.id.includes(variantId);
        });
      })
      .forEach((lineItem) => {
        dispatch('changeQty', { id: lineItem.id, quantity: 0 });
      });

    await debouncedUpdateLineItems.flush();

    return Promise.resolve(true);
  },

  /**
   * updates quantity of lineItem represented by id to quantity
   * triggers tracking with "add" event with delta of one
   * @param {Object} payload - { id, quantity } id is lineItem id, quantity is new quantity
   */
  changeQty({ commit, dispatch }, { id, quantity }) {
    commit('SET_PAYLOAD', { id, quantity });
    dispatch('updateLineItems');
  },

  /**
   * updates quantity of lineItems represented by id to quantity
   */
  updateLineItems: debouncedUpdateLineItems,

  async pickystoryRefreshCart(ctx, checkout) {
    if (window && window.pickystory) {
      const { client } = window.pickystory;

      try {
        await client.refreshCart();

        return Promise.resolve(true);
      } catch (e) {
        this.$sentry.captureException(e);

        return Promise.resolve(false);
      }
    }

    return Promise.resolve(false);
  },

  /**
   * clear client checkout by removing the reference in local storage
   * */
  resetCart({ commit, dispatch }) {
    commit('SET_CART', null);
    dispatch('initCart');
  },

  /**
   * persist cartId to local storage
   */
  persistCart(_, cartId) {
    if (localStorageCheck()) {
      localStorage.setItem(`shopify_cart_${this.$i18n.locale}_id`, cartId);
    } else {
      console.error('localstorage is not available');
    }
  },

  trackLineItemChanges({ dispatch, getters }, payload) {
    const removedItems = [];
    const addedItems = [];

    payload.forEach((item) => {
      const lineItem = getters.lineItems.find((li) => li.id === item.id);
      const variant = lineItem?.variant;
      const delta = Math.abs(lineItem.quantity - item.quantity);
      const variantsDeltas = { variant, delta };

      if (delta > 0) {
        if (lineItem.quantity > item.quantity) {
          removedItems.push(variantsDeltas);
        } else {
          addedItems.push(variantsDeltas);
        }
      }
    });

    if (removedItems.length > 0) {
      try {
        dispatch('gtmEECremoveFromCart', removedItems);
      } catch (e) {
        this.$sentry.captureException(e);
      }
    }

    if (addedItems.length > 0) {
      try {
        dispatch('gtmEECaddToCart', addedItems);
      } catch (e) {
        this.$sentry.captureException(e);
      }
    }

    return Promise.resolve();
  },

  /**
   * push EEC tracking for adding to cart
   * @param {Array} variantsDeltas - array of cart.lineItems.variants and their delta of quantity
   */
  async gtmEECaddToCart({ state }, variantsDeltas) {
    const products = await gtmEECProducts(variantsDeltas, this.$shopifyBuyCLient());
    const data = {
      event: 'EECaddToCart',
      ecommerce: {
        currencyCode: state.cart.currencyCode,
        add: {
          products,
        },
      },
    };
    this.$_gtm.push(data);
  },

  /**
   * push EEC tracking for removing from cart
   * @param {Array} variantsDeltas - array of lineItems.variants and their delta of quantity
   */
  async gtmEECremoveFromCart(_, variantsDeltas) {
    // enrich lineItem with shopifyProduct data
    const products = await gtmEECProducts(variantsDeltas, this.$shopifyBuyCLient());
    const data = {
      event: 'EECremoveFromCart',
      ecommerce: {
        remove: {
          actionField: { list: null }, // if available: product list eg. 'Bestseller'
          products,
        },
      },
    };
    this.$_gtm.push(data);
  },

  async createOrUpdateCartAttributes({ state, commit }, attributes = []) {
    const newAttributes = [...state.cart.attributes, ...attributes];

    const cart = await this.$shopifyClient.updateAttributes(state.cart.id, newAttributes);

    commit('SET_CART', cart);
  },

  async checkoutUrl({ commit, state, dispatch }) {
    if (this.$config && this.$config.auth.enabled && this.$auth.user && this.$auth.user.isVerified) {
      try {
        const multipassResponse = await this.$auth.strategies.oneworld.getMultipass(
          this.$i18n.locale,
          state?.cart?.checkoutUrl
        );

        const multipass = multipassResponse.data.data.shopifyMultipass.token;
        const locale = this.$i18n.locales.find((l) => l.code === this.$i18n.locale);

        const url = `https://${locale.shopifyApiDomain}/account/login/multipass/${multipass}`;

        return url;
      } catch {
        console.warn('could not login to shopify');
      }
    }

    const consentSettings = this.$getCookieConsent();

    if (consentSettings) {
      const attributes = [
        {
          key: 'ConsentId',
          value: consentSettings.consentId,
        },
      ];

      Object.keys(consentSettings.consent).forEach((key) => {
        if (key in consentSettings.consent) {
          attributes.push({
            key: `Consent_${key}`,
            value: consentSettings.consent[key] ? 'true' : 'false',
          });
        }
      });

      await dispatch('createOrUpdateCartAttributes', attributes);
    }

    // FIXME: disabled for now, as we don't need it anymore
    // read out cookie settings & pass as custom attribute
    // const hasConsentAttribute = state.cart?.attributes?.findIndex((attr) => attr.key === 'OptanonConsent') !== -1;
    // const consentCookie = getCookie('OptanonConsent');

    // if (!hasConsentAttribute && consentCookie) {
    //   await dispatch('createOrUpdateCartAttributes', [
    //     {
    //       key: 'OptanonConsent',
    //       value: 'OptanonConsent=' + consentCookie,
    //     },
    //   ]);
    // }

    if (this.$shopifyClient.resolveCountry() !== state.cart.buyerIdentity.countryCode) {
      const cart = await this.$shopifyClient.updateBuyerIdentity(state.cart.id, {
        countryCode: this.$shopifyClient.resolveCountry(),
      });

      commit('SET_CART', cart);
    }

    return state?.cart?.checkoutUrl || null;
  },
};

export const getters = {
  isProcessing(state) {
    return state.isSubmitting > 0 || state.isAsyncSubmitting > 0 || Object.keys(state.payload).length > 0;
  },
  lineItems(state) {
    if (state.cart?.lines?.nodes?.length) {
      return state.cart.lines.nodes;
    }

    return [];
  },
  cartCount(state, getters) {
    if (!state.cart) {
      return 0;
    }

    return state.cart.totalQuantity;
  },

  hasDiscountApplied(state) {
    if (!state.cart) return false;

    return (
      state.cart.discountCodes &&
      state.cart.discountCodes.length >= 1 &&
      state.cart.discountCodes.some((d) => d.applicable)
    );
  },

  discountValue(state, getters) {
    let cartDiscount = 0;
    let productDiscounts = 0;
    let currencyCode = 'EUR';

    if (state.cart.discountAllocations && state.cart.discountAllocations.length > 0) {
      cartDiscount = state.cart.discountAllocations.reduce((acc, curr) => acc + +curr.discountedAmount.amount, 0);
      currencyCode = state.cart.discountAllocations[0].discountedAmount.currencyCode;
    }

    if (getters.lineItems.length > 0) {
      productDiscounts = getters.lineItems.reduce((acc, curr) => {
        return acc + curr.discountAllocations.reduce((acc, curr) => acc + +curr.discountedAmount.amount, 0);
      }, 0);
    }

    return { amount: cartDiscount + productDiscounts, currencyCode };
  },

  appliedDiscountCode(state, getters) {
    if (getters.hasDiscountApplied) {
      return state.cart.discountCodes.find((node) => node.applicable).code;
    }

    return false;
  },

  hasFreeShipping(state) {
    try {
      const totalAmount = Number.parseFloat(state.cart.cost.totalAmount?.amount) ?? 0;

      return totalAmount >= state.freeShippingThreshold;
    } catch (e) {
      return false;
    }
  },
};

export const mutations = {
  OPEN(state) {
    state.isOpen = true;
  },
  CLOSE(state) {
    state.isOpen = false;
  },
  SET_CART(state, cart) {
    if (cart && cart.id) {
      this.$pickystory.initPickystoryCart(cart.id);
    }

    state.cart = cart;
  },

  SET_FREE_SHIPPING_THRESHOLD(state, threshold) {
    state.freeShippingThreshold = threshold;
  },
  SET_SUBMITTING(state, isSubmitting) {
    state.isSubmitting += isSubmitting ? 1 : -1;
  },
  SET_ASYNC_SUBMITTING(state, isAsyncSubmitting) {
    state.isAsyncSubmitting += isAsyncSubmitting ? 1 : -1;
  },
  SET_PAYLOAD(state, item) {
    state.payload = { ...state.payload, [item.id]: item.quantity };
  },
  RESET_PAYLOAD(state) {
    state.payload = {};
  },
};
